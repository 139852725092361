import { mapGetters, mapActions } from 'vuex'
import QuoteRoutes from './route-types'

const moduleName = 'quotes'

export default {
  created() {
    this.getCompanyItemTypes()
    this.getJobStages() // Store job stages in vuex
    // this.getExternalSetting('partscheck')
    this.getSubJobStages() // Store job stages in vuex
    this.getJobNSubJobStages()
    this.getExternalSetting('partscheck')
    // if (this.$route.name !== QuoteRoutes.QuoteListView.name && this.value) {
    //   console.log('get vvvv')
    //   this.getQuoteVehicle(this.value.vehicleId)
    // }
  },
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff',
      companyItemTypes: 'companyItemTypes',
      currentVehicleSnapshot: 'currentVehicleSnapshot',
      currentCustomerSnapshot: 'currentCustomerSnapshot',
      currentInsurerSnapshot: 'currentInsurerSnapshot',
      quoteVehicle: 'quoteVehicle',
      quoteCustomer: 'quoteCustomer',
      quoteInsurer: 'quoteInsurer',
      emtaVehicleStore: 'emtaVehicle',
      standardLabourItems: 'standardLabourItems',
      ntarLoadingItems: 'ntarLoadingItems',
      ntarOtherLoadingItems: 'ntarOtherLoadingItems',
      ntarLoadingValues: 'ntarLoadingValues',
      jobStages: 'jobStages',
      subJobStages: 'subJobStages',
      jobNSubJobStages: 'jobNSubJobStages',
      repairDelayReasons: 'repairDelayReasons',
      partsCheckSettingExists: 'partsCheckSettingExists',
      readOnlyView: 'readOnlyView',
      previousQuoteId: 'previousQuoteId',
      audanetLock: 'audanetLock',
      snapshotDiffVehicle: 'snapshotDiffVehicle',
      snapshotDiffCustomer: 'snapshotDiffCustomer',
      snapshotDiffInsurer: 'snapshotDiffInsurer',
      partsCheckSetting: 'partsCheckSetting'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, [
      'load',
      'getStoreItem',
      'addStoreItem',
      'addStoreSupplementaryItem',
      'saveSnapshot',
      'clearSnapshots',
      'clearHeaders',
      'setReturnRoute',
      'getCompanyItemTypes',
      'getQuoteVehicle',
      'getNewVehicle',
      'removeQuoteVehicle',
      'getQuoteCustomer',
      'getNewCustomer',
      'removeQuoteCustomer',
      'getEmtaVehicle',
      'setEmtaVehicle',
      'resetEmtaVehicle',
      'clearInvoiceState',
      'copyStoreQuote',
      'getQuoteInsurer',
      'getNewInsurer',
      'setQuoteInsurer',
      'removeQuoteInsurer',
      'getStandardLabourItems',
      'getNtarLoadingItems',
      'getNtarOtherLoadingItems',
      'removeLabourItems',
      'getNtarLoadingValues',
      'getJobStages',
      'getSubJobStages',
      'getJobNSubJobStages',
      'removeJobStages',
      'getExternalSetting',
      'setReadOnlyView',
      'setPreviousQuoteId',
      'setAudaNetLock',
      'saveVehicleSnapshot',
      'saveCustomerSnapshot',
      'saveInsurerSnapshot',
      'resetJobNSubJobStages'
    ]),
    ...mapActions('insurers', { setInsurerReturnRoute: 'setReturnRoute', editStoreInsurer: 'editStoreItem', addStoreInsurer: 'addStoreItem' }),
    ...mapActions('vehicles', { setVehicleReturnRoute: 'setReturnRoute', editStoreVehicle: 'editStoreItem', addStoreVehicle: 'addStoreItem' }),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  },
  beforeRouteLeave: async function (to, from, next) {
    // remove quote vehicle, customer & insurer vuex objects when leaving quote pages
    const isQuoteRoutes = Object.values(QuoteRoutes)
      .filter((r) => r.name !== QuoteRoutes.QuoteListView.name)
      .find((r) => r.name === to.name)
    if (!isQuoteRoutes) {
      this.resetEmtaVehicle()
      this.removeLabourItems()
    }
    next()
  }
}
